/* TopView.module.css */
.ukPositionCenter {
  top: 55%;
}

.ukFlexCenter {
  margin-top: 25%;
}

.ukButtonPrimary {
  border-radius: 5px;
  background-color: rgb(255, 181, 131);
  color: #ffffffcc;
}

.ukButtonPrimary:hover, .ukButtonPrimary:focus {
  color: #ffffffe6;
  background-color: rgb(252, 219, 196);
}

.ukCard {
  margin: auto;
  padding-top: 100px;
  text-align: center;
}

.ukCardBottom {
  box-shadow: 0 0 10px rgb(0 0 0 / 8%);
}

.ukH4 {
  line-height: 35px;
  margin-top: 30px;
  margin-bottom: 50px;
  font-family: serif;
  color: #5f5e5e;
}

.ukH6 {
  margin: 0;
  font-family: serif;
  color: #5f5e5e;
}

.lightletterNameGray {
  width: 250px;
}

.divNormal {
  text-align: left;
  line-height: 35px;
  margin-top: 30px;
  color: #5f5e5e;
  vertical-align: middle;
}

.divBold {
  text-align: left;
  line-height: 35px;
  margin-top: 30px;
  color: #5f5e5e;
  vertical-align: middle;
  font-weight: bold;
}

.divBoldOrange {
  color: rgb(255, 137, 43);
}

.divEng {
  text-align: center;
  padding: 30px;
  color: #5f5e5e;
  font-family: Noto Sans JP,serif;
  font-size: x-large;
}

.divEngBorderTop {
  padding: 50px;
  margin: 0px;
  text-align: center;
  color: #5f5e5e;
  font-family: Noto Sans JP,serif;
  font-size: x-large;
  border-top: 1px solid rgb(255, 240, 229);
}

.ukGrid {
  padding: 0px 20px 50px 20px;
}