.designsMain {
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: rgb(119, 119, 119);
  background-size: contain;
  overflow: hidden;
}

.ukButton {
  margin: 3vh;
}

.ukButtonDefault {
  padding: 1vh 1.5vw 1vh 1.5vw;
  border-color: #0000000a;
  position: relative;
}

.ukButtonDefault:hover {
  padding: 1vh 1.5vw 1vh 1.5vw;
  border-color: rgb(255, 219, 195);
  position: relative;
}

:global(.active).ukButtonDefault {
  border-color: rgb(255, 219, 195);
  background-color: rgb(255, 254, 253);
}

:global(uk-button-default).ukButtonDefault:hover {
  border-color: rgb(255, 219, 195) !important;
  background-color: rgb(255, 254, 253) !important;
  color: rgb(255, 219, 195) !important;
}

.ukChildWidthExpand > :global(.uk-active) > a{
  border-color: rgb(255, 219, 195);
  color: rgb(255, 137, 43);
}

.ukButtonPrimary {
  display: block;
  margin: 0 0 0 auto;
  margin-bottom: 3vh;
  border-radius: 5px;
  padding-right: 3vw;
  padding-left: 3vw;
  background-color: rgb(253, 177, 127);
  z-index: 1;
}

.ukButtonPrimary:hover, .ukButtonPrimary:focus {
  background-color: rgb(253, 218, 189);
}

.ukGridDivider {
  margin-right: 3vw;
  margin-left: 3vw;
}

.ukGridDivider > :global(.uk-first-column) {
  padding-left: 0px;
}

.hr {
  margin-top: 0px;
  border-color: #f8f8f8;
}

.ukBadge {
  font-family: Poppins;
}

.ukTextCenter {
  color: #5f5e5e;
}