.myfonts {
	top: 80px;
	margin-bottom: 5vh;
}

.ukH3 {
    color: rgb(252, 164, 105);
}

.inputButtomContainer {
	cursor: pointer;
	font-size: initial !important;
}

.textCenter{
	margin: 0;
	height: 50px;
	margin-left: 40px;
	vertical-align: middle;
	z-index: 0 !important;
	font-size: 18px;
	align-items: center;
	border: dashed 1.5px #000000 !important;
	transition: background 0.2s;
	display: flex;
	justify-content: center;
}

.textCenter:hover{
	background-color: #dddddd;
}

.dragImage{
	max-width: 20%;
	height: auto;
	display: inline-block;
}

.myFontUnregisterd{
	display: inline-block;
	text-align: center;
	margin-left: 40px;
	font-size: 18px;
	border: solid 1.5px rgb(150, 150, 150);
}

.myFontRegisterd{
	display: inline-block;
	text-align: center;
	margin-left: 40px;
	font-size: 30px;
	border: solid 1.5px rgb(150, 150, 150);
}

.ukButtonPrimary {
	margin-top: 40px;
	border-radius: 5px;
	background-color: rgb(255, 181, 131);
	color: white;
}
  
.ukButtonPrimary:hover {
	color: white;
	background-color: rgb(252, 219, 196);
}