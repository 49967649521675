.draft {
	top: 80px;
}

.draftItemContainer {
	border: 0px solid;
	border-radius: 5px;
}

.draftItemDate {
  margin-top: 0px;
  margin-right: 0px;
}

.ukEnvelopeImg {
  object-fit: contain;
}

.ukLetterBackgroundImg {
  margin-top: 15px;
  object-fit: contain;
}

.ukCloseButton > svg {
  width: 12px;
  height: 12px;
}

.ukH3 {
  color: rgb(252, 164, 105);
}

.ukButtonPrimary {
  color: #fff !important;
  border-radius: 5px;
  background-color: rgb(253, 177, 127) !important;
}

.ukButtonPrimary:hover, .ukButtonPrimary:focus {
  background-color: rgb(253, 218, 189) !important;
}
