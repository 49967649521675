.sideBar {
  position: fixed;
  top: 80px;
  bottom: 0;
  box-sizing: border-box;
  width: 240px !important;
  padding: 15px 0px 0px 0px;
  overflow: auto;
  background-color: white;
  border-right: 1px solid #f1f1f1;
}

.ukNavDivider {
  margin: 10px 15px;
  border-top: 1px solid #f1f1f1 !important;
}

.menu {
  padding-left: 20px;
}

.active {
  background-color: #c9daf8ff;
  border-radius: 0px 20px 20px 0px;
  color: orange;
  background-color: rgb(255, 250, 246);
}

.text {
  color: rgb(65, 65, 65) !important;
}

.activeText {
  color: #999 !important;
  background-color: rgb(255, 250, 246);
  vertical-align: middle;
}

.icon {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  height: auto;
  vertical-align: middle;
  color: rgb(65, 65, 65);
}

.keyIcon {
  vertical-align: middle;
  color: rgb(65, 65, 65);
}

.activeIcon {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  height: auto;
  vertical-align: middle;
  color: #999 !important;
}

.activeKeyIcon {
  vertical-align: middle;
  color: #999 !important;
}

.offcanvas {
  background-color: #fff;
  border-right: 1px solid #ebeaea;
  padding: 0px;
}

.drawerItem {
  padding-left: 20px !important;
}

.disableItem {
  background-color: rgb(250, 250, 250);
  position: relative;
}

.responsibleSideBar {
  background-color: white;
  bottom: 0px !important;
  box-shadow: 0px 12px 12px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  left: -240px;
  overflow: auto;
  padding: 15px 0px 0px 0px;
  position: fixed;
  top: 80px !important;
  transition: .5s cubic-bezier(0, 1, 0, 1);
  width: 240px !important;
  z-index: 500;
}

.responsibleSideBar.opened {
  left: 0px;
  transition: .5s cubic-bezier(0, 1, 0, 1);
}

.shadow {
  display: none;
  overflow: hidden;
  position: fixed;
  opacity: 0;
  background: rgba(0, 0, 0, .32);
  transition: opacity .5s cubic-bezier(0, 1, 0, 1);
}

.shadow.opened {
  opacity: 1;
  background: rgba(0, 0, 0, .32);
  transition: opacity .5s cubic-bezier(0, 1, 0, 1);
}

@media (min-width: 960px) {
  .shadow {
    transition: opacity .5s cubic-bezier(0, 1, 0, 1);
    display: none;
  }
}

.ukDivButtonPrimary {
  bottom: 20px;
}

.ukButtonPrimary {
  color: #fff !important;
  border-radius: 10px;
  margin-top: 30px;
  background-color: rgb(253, 177, 127) !important;
  box-shadow: 10px 10px 20px -10px rgba(0,0,0,0.2);
}

.ukButtonPrimary:hover, .ukButtonPrimary:focus {
  background-color: rgb(253, 218, 189) !important;
}
