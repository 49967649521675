.app-title {
  font-family: "Lobster", sans-serif;
}

.uk-navbar-item {
  background: #F8F8F8;
  color: #666;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::before {
  position: absolute; width:0; height:0; overflow:hidden; z-index:-1;
  content:
    /* images in top view */
    url('./assets/imgs/top-background-title.jpg')
    url('./assets/imgs/step1-image.png')
    url('./assets/imgs/step2-image.png')
    url('./assets/imgs/step3-image.png')
    url('./assets/imgs/myfont-example.svg')
    url('./assets/imgs/lightletter-logo-and-name.svg')
    url('./assets/imgs/lightletter-name-gray.svg')
}

body::after {
  position: absolute; width:0; height:0; overflow:hidden; z-index:-1;
  content:
    /* envelopes in design view */
    url('./assets/imgs/envelope-blue.png')
    url('./assets/imgs/envelope-green.png')
    url('./assets/imgs/envelope-ivory.png')
    url('./assets/imgs/envelope-yellow.png')
    url('./assets/imgs/envelope-orange.png')
    url('./assets/imgs/envelope-pink.png')
    url('./assets/imgs/envelope-purple.png')
    url('./assets/imgs/envelope-skin.png')
    url('./assets/imgs/envelope-birthday.png')
    url('./assets/imgs/envelope-christmas.png')
    /* letter papers in design view */
    url('./assets/imgs/letter-paper-simple1.png')
    url('./assets/imgs/letter-paper-simple2.png')
    url('./assets/imgs/letter-paper-heart.png')
    url('./assets/imgs/letter-paper-pink.png')
    url('./assets/imgs/letter-paper-leaf.png')
    url('./assets/imgs/letter-paper-snow.png')
    url('./assets/imgs/letter-paper-sakura.png')
    url('./assets/imgs/letter-paper-maple.png')
    url('./assets/imgs/letter-paper-birthday.png')
    url('./assets/imgs/letter-paper-christmas.png')
    /* images in mypage */
    url('./assets/imgs/draft.jpg')
    url('./assets/imgs/myFont.jpg')
    url('./assets/imgs/completeLetter.jpg')
    url('./assets/imgs/recieveLetter.jpg')
    /* images in sign in page */
    url('./assets/imgs/lightletter-name.svg')
    url('./assets/imgs/twitter.svg')
    url('./assets/imgs/google-icon.svg')
}
