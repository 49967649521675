.title {
  display: inline-block;
  margin: 0px;
  font-size: 20px;
}
.closeButton {
  color: rgb(80 80 80);
  font-size: 18px;
  float: right;
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
}