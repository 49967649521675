.draft {
  top: 80px;
}

.ukH3 {
  color: rgb(252, 164, 105);
}

.deleteButton {
  float: right;
  border-radius: 5px;

}

.settingViewButton {
  border-radius: 5px;
  background-color: rgb(239, 239, 239);
}
