.ukH3 {
    color: rgb(252, 164, 105);
}
.ukH5 {
    margin: 0 0 3vh 0;
}
.dropdownMenu {
    color: #666;
}
.mailTemplateView {
    background-color: #ffffff;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    font-size: calc(5px + 2vmin);
    margin: 5vh 5vw
}
.mailTypes {
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: #999;
    padding: 5px 0;
}
.mailTypes:hover {
    color: #666;
}
.ukButtonPrimary {
    border-radius: 5px;
    background-color: rgb(253, 177, 127);
    margin: 3vh 0;
    }
.ukButtonPrimary:hover, .ukButtonPrimary:focus {
    background-color: rgb(253, 218, 189);
}

:global(.uk-notification-message) {
    background: rgb(102, 102, 102);
    color: white;
    font-size: 1.0rem;
    margin-left: 3vh;
}