.loginButton {
  border-radius: 3px;
}

.termsButton, .policyButton {
  font-size: 13px;
  color: rgb(117, 117, 117);
}

.loginFrame {
  padding: 50px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 3px;
}