.editviewMain {
  background-color: #ffffff;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: #307ff7;
  margin: 5vw;
}

.ukPositionCenter {
  transform: translate(-50%, -150px);
}

.ukOverlayDefault {
  margin: 20% 15% 20% 15%;
  background: none;
  position: absolute;
}

.ukOverlayImageArea {
  margin: 0%;
  background: none;
  position: absolute;
}

.form,
.ukFieldset {
  height: 100%;
}

.ukTextarea {
  z-index: 1;
  position: relative;
  border: none;
  height: 100%;
  resize: none;
  background: transparent;
  background-color: transparent !important;
  color: #666;
  overflow: hidden;
}

.ukAlertDanger {
  font-size: 15px;
  border-radius: 5px;
  position: fixed;
  right: 20px;
  margin: 0px 20px;
  padding: 10px;
  z-index: 1;
}

.ukSpan {
  margin: 0px 5px 0px 0px;
}

.form {
  position: relative;
  z-index: 1;
}

.img {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}

.stamp {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}

.contextmenu {
  position: absolute !important;
  width: 120px;
  height: 84px;
  z-index: 3 !important;
  display: inline-block;
  background-color: white;
  padding: 10px;
  border-radius: 5px; /* 要素の角の丸み具合 */
  box-shadow: 2px 2px 2px grey;
  transform-origin: center;
  transition: transform 0.2s, opacity 0.2s;
}

.ul {
  padding: 0px;
  width: 100%;
  height: auto;
}

.menuitem {
  /* display: inline-block; */
  width: calc(200% / 2);
  /* width: 45; */
  /* height: 28; */
  padding: 0px 10px;
  transition: 0.1s;
  color: gray;
  font-size: 18px;
  color: black;
  box-sizing: border-box;
}

.liimg {
  display: inline-block;
  width: calc(100% / 2);
  vertical-align: middle;
}

.litext {
  display: inline-block;
  width: calc(100% / 2);
}

.bin {
  display: block;
  width: 50%;
  height: auto;
}

.menuitem:hover {
  background-color: #eee;
  cursor: pointer;
}

.uk-notification-message {
    /* background: rgb(102, 102, 102); */
    /* color: white; */
    font-size: 1.0rem;
    margin-left: 3vh;
}
.uk-notification-message-danger {
    background: #fef4f6;
    color: #f0506e;
    font-size: 1.0rem;
    margin-left: 3vh;
}

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Noto+Sans+JP&family=Noto+Serif+JP&family=Open+Sans&family=Oswald&family=Poppins&family=Roboto&family=Roboto+Condensed&family=Roboto+Mono&family=Source+Sans+Pro&family=Sawarabi+Gothic&family=Kosugi+Maru&family=Kiwi+Maru&family=New+Tegomin&family=Yusei+Magic&family=Train+One&family=RocknRoll+One&display=swap');
