.ukNavbarContainer:not(.uk-navbar-transparent) {
  background: #ffffff;
  padding: 5px;
}

.ukNavbarLeft,
.ukNavbarRight {
  background: #ffffff;
}

.FontSelectDropDown > :global(#font-picker) {
  margin: 2vw;
  box-shadow: none;
  border: 1px solid #bdbcbc;
}

.FontSelectDropDown > :global(#font-picker) > button {
  background: #ffffff;
}

.FontSelectDropDown > :global(#font-picker) > ul {
  background: #f7f6f6;
}

.ukButtonPrimary {
  margin: 2.5px;
  border-radius: 5px;
  background-color: rgb(253, 177, 127);
}

.ukButtonPrimary:hover, .ukButtonPrimary:focus {
  background-color: rgb(253, 218, 189);
}

.hr {
  margin-top: 0px;
  border-color: #f8f8f8;
}

.dropButton {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
}

.navbarButton {
  margin: 2.5px;
  box-shadow: none;
  border: 1px solid #bdbcbc;
  pointer-events: auto !important;
}

.buttonBorder {
  border: none;
}

.ukIcon {
  bottom: 6px;
  position: relative;
}

.ukIconText {
  bottom: 8px;
  position: relative;
  line-height: 0;
  font-size: 8px;
  font-feature-settings: "palt" 1;
}
