.dropButton{
    opacity: 0.5;
    background-color: white;
    color: black;
}

.navbarButton {
    margin: 2.5px;
    box-shadow: none;
    border: 1px solid #bdbcbc;
}

.dropButton:hover {
    opacity: 1;
    background-color: whitesmoke;
}

.uk-divider{
    height: 3px !important;
}

.fontDropDown{
    width: 150px;
    border: none;
}

.divider{
    border-top: 1px solid #bbb;
}
