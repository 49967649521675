.mainContainer{
  padding-top: 20px;
  transition: .5s cubic-bezier(0, 1, 0, 1);
  z-index: 300;
}

.scrollContainer {
  padding-left: 40px;
  padding-right: 40px;
}

.menuIcon {
  height: 100%;
}

.mainContainer.opened {
  transition: .5s cubic-bezier(0, 1, 0, 1);
}

body.opened {
  overflow: hidden;
}

/* 画面幅が広いとき */
@media (min-width: 960px) {
  .mainContainer{
    padding-left: 240px;
  }

  body.opened {
    overflow: visible;
  }
}

.ukButtonPrimary {
  color: #fff !important;
  border-radius: 10px;
  margin-top: 30px;
  background-color: rgb(253, 177, 127) !important;
  box-shadow: 10px 10px 20px -10px rgba(0,0,0,0.2);
}

.ukButtonPrimary:hover, .ukButtonPrimary:focus {
  background-color: rgb(253, 218, 189) !important;
}
