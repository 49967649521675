.draft {
	top: 80px;
}

.ukAlert {
  border-radius: 5px;
}

.ukProgress::-webkit-progress-value {
  background-color: rgb(252, 164, 105);
}

.ukProgress::-moz-progress-bar {
  background-color: rgb(252, 164, 105);
}

.ukProgressDanger::-webkit-progress-value {
  background-color: #f0506e;
}
.ukProgressDanger::-moz-progress-bar {
  background-color: #f0506e;
}

.ukH3 {
  color: rgb(252, 164, 105);
}