.homeSetting {
	top: 80px;
	margin-bottom: 30vh;
}

.homeItemContainer {
	border: 1px solid;
	border-radius: 4px;
	padding: 10px;
}

.wirteButton {
	border-radius: 20px;
  width: 200px;
  position: fixed;
  right: 5vw;
  bottom: 10vh;
  z-index: 1;
}

.logoutButton {
	border-radius: 20px;
  width: 200px;
  position: fixed;
  right: 5vw;
  bottom: 20vh;
  z-index: 1;
}

.ukCardTopRadius {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.ukCardBottomRadius {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}