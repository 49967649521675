.menuIcon {
  height: 100%;
}

.productArea {
  margin: 20px;
}

.card {
  border: solid 1px #dadada;
  border-radius: 5px;
  box-shadow: initial;
}

ul {
  list-style:none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.listsample li {
  list-style-type: none !important;
  list-style-image: none !important;
  margin: 5px 0px 5px 0px !important;
}

.check li {
  position: relative;
  padding-left: 30px;
}

.check li:after, .check li:before {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 9px;
  height: 11px;
  width: 3px;
  background: #37de45;
  border-radius: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.check li:before {
  top: 10px;
  left: 3px;
  height: 8px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.check li:last-child p {
  margin-bottom: 0px;
}

.registerButton {
  background-color: #f79f00;
  color: #fff;
  border-radius: 5px;
}

.registerButton:hover {
  background-color: #ffa600;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(247, 159, 0, .35);
}

.registerButton:disabled {
  border: solid 1px rgb(192, 192, 192);
  background-color: rgb(255, 255, 255);
  color: rgb(138, 138, 138)
}

.registerButton:disabled:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: initial;
}

.discription {
  margin-top: 25px;
}

.line {
  z-index: -1;
  position: absolute;
  margin-left: -600px;
  height: 10px;
  width: 2300px;
  background-color: #f7a10093;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.line2 {
  z-index: -1;
  position: absolute;
  margin-top: 30px;
  margin-left: -600px;
  height: 30px;
  width: 2300px;
  background-color: #f7a10093;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.line3 {
  z-index: -1;
  position: absolute;
  margin-top: 40px;
  margin-left: -1000px;
  height: 30px;
  width: 2500px;
  background-color: #f7a10093;
  transform: rotate(20deg);
  -webkit-transform: rotate(20deg);
  -o-transform: rotate(20deg);
}

.line4 {
  z-index: -1;
  position: absolute;
  margin-top: 300px;
  margin-left: -300px;
  height: 30px;
  width: 2300px;
  background-color: #f7a10093;
  transform: rotate(-20deg);
  -webkit-transform: rotate(-20deg);
  -o-transform: rotate(-20deg);
}

.loginButton {
  border-radius: 3px;
}

.couponContainer {
  margin-left: 20px;
}